import { format, formatDateAPI, formatURL } from "../../common/utilities/format";
import { IItem, IItemPage, ISearchPage } from "../types/item";
import { INetworkPhoto, IPhoto, ITag, ITagItem } from "../types/photo";
import { getItem } from "./item";
import { graphBlob, graphJSON, graphNoContent, graphResponse } from "./network";
import { escapeODataQuote, getManifestParams, preparePhoto } from "./util";

export interface ITagApi {
  addTag(fetch: (url: string, init?: RequestInit) => Promise<Response>, driveId: string, photoId: string, tagName: string): Promise<void>;
  deleteTag(fetch: (url: string, init?: RequestInit) => Promise<Response>, driveId: string, photoId: string, tag: ITag): Promise<void>;
}

const cobManageTagsApi = "{{vroomOrigin}}/{{apiVersion2}}/drives/{{driveId}}/tags";

export const cobTagApi: ITagApi = {
  addTag(fetch: (url: string, init?: RequestInit) => Promise<Response>, driveId: string, photoId: string, tagName: string): Promise<void> {
    const body = JSON.stringify({
      itemIds: [photoId],
      tagsToAdd: [{ name: tagName }],
      tagsToDelete: []
    });

    return graphResponse<void>(
      fetch(formatURL(cobManageTagsApi, { driveId }), {
        headers: { "Content-Type": "application/json" },
        body,
        method: "POST"
      }),
      (response) => {
        // noresponse checks for 204 - i asked them to pdate the api to return 204 instead of 200
        // todo: UmutAkkaya this once the endpoint is updated
        return response.ok ? Promise.resolve() : Promise.reject(response);
      }
    );
  },
  deleteTag(fetch: (url: string, init?: RequestInit) => Promise<Response>, driveId: string, photoId: string, tag: ITag): Promise<void> {
    const body = JSON.stringify({
      itemIds: [photoId],
      tagsToAdd: [],
      tagsToDelete: [tag]
    });

    return graphResponse<void>(
      fetch(formatURL(cobManageTagsApi, { driveId }), {
        headers: { "Content-Type": "application/json" },
        body,
        method: "POST"
      }),
      (response) => {
        // noresponse checks for 204 - i asked them to pdate the api to return 204 instead of 200
        // todo: UmutAkkaya this once the endpoint is updated
        return response.ok ? Promise.resolve() : Promise.reject(response);
      }
    );
  }
};

const odcAddTagApi = "{{vroomOrigin}}/{{apiVersion1}}/drives/{{driveId}}/items/{{photoId}}/tags";
const odcDeleteTagApi = "{{vroomOrigin}}/{{apiVersion1}}/drives/{{driveId}}/items/{{photoId}}/tags/{{tagName}}";

export const odcTagApi: ITagApi = {
  addTag(fetch: (url: string, init?: RequestInit) => Promise<Response>, driveId: string, photoId: string, tagName: string): Promise<void> {
    return graphResponse<void>(
      fetch(formatURL(odcAddTagApi, { driveId, photoId }), {
        body: JSON.stringify({ name: tagName }),
        headers: { "Content-Type": "application/json" },
        method: "POST"
      }),
      graphJSON
    );
  },
  deleteTag(fetch: (url: string, init?: RequestInit) => Promise<Response>, driveId: string, photoId: string, tag: ITag): Promise<void> {
    return graphResponse<void>(
      fetch(formatURL(odcDeleteTagApi, { driveId, photoId, tagName: tag.name }), {
        method: "DELETE"
      }),
      graphNoContent
    );
  }
};

const deleteApi = "{{vroomOrigin}}/{{apiVersion2}}/drives/{{driveId}}/items/{{photoId}}";

export function deletePhoto(fetch: (url: string, init?: RequestInit) => Promise<Response>, driveId: string, photoId: string): Promise<void> {
  return graphResponse(fetch(formatURL(deleteApi, { driveId, photoId }), { method: "DELETE" }), graphNoContent);
}

export interface IDownloadOptions {
  cacheToken?: string;
  photoScale?: "full" | "manifest" | string | undefined;
  format?: "mp4" | "dash" | "hls";
}

const downloadApi = "{{vroomOrigin}}/{{downloadVersion}}/drives/{{driveId}}/items/{{photoId}}/content?{{parameters}}";
const thumbnailApi = "{{vroomOrigin}}/{{downloadVersion}}/drives/{{driveId}}/items/{{photoId}}/thumbnails/0/{{photoScale}}/content?{{parameters}}";

export function downloadPhoto(
  fetch: (url: string, init?: RequestInit) => Promise<Response>,
  driveId: string,
  photoId: string,
  options?: IDownloadOptions
): Promise<Blob> {
  const { cacheToken, photoScale, format } = options || {};
  const params: { [parameterName: string]: string } = {};

  if (photoScale === "full" || photoScale === undefined) {
    return graphResponse(
      fetch(formatURL(downloadApi, { driveId, photoId, parameters: new URLSearchParams(params) }), { cache: "default" }),
      graphBlob
    );
  }

  if (photoScale === "manifest") {
    const manifestParams = getManifestParams({ params, format });

    return graphResponse(fetch(formatURL(downloadApi, { driveId, photoId, parameters: manifestParams }), { cache: "default" }), graphBlob);
  }

  params.prefer = "noredirect,closestavailablesize";

  if (cacheToken) {
    params.cb = cacheToken;
  }

  return graphResponse(
    fetch(formatURL(thumbnailApi, { driveId, photoScale, photoId, parameters: new URLSearchParams(params) }), { cache: "default" }),
    graphBlob
  );
}

/**
 * By default when requesting a photo/video through single or batch calls like
 * getPhoto or getPhotos you can supply the set of properties you want back.
 *
 * The defaultSelect value represents the set of properties retrieved when the
 * caller doesn't supply an option for select.
 */
export const defaultPhotoSelect =
  "createdBy,createdDateTime,description,driveId,file,fileSystemInfo,id,image,lastModifiedDateTime,location,name,parentReference,photo,shared,sharepointIds,size,tags,video,viewpoint,webUrl";

export interface IGetTagsOptions extends IGeneralPhotoOptions {
  /**
   * The filter for the type of tagged photos.
   *
   * @default locations
   */
  tagType?: "locations" | "things";
}

const allTagsApi = "{{vroomOrigin}}/{{apiVersion2}}/drives/{{driveId}}/view.listTags?{{parameters}}";
const placeTagsApi = "{{vroomOrigin}}/{{apiVersion2}}/drives/{{driveId}}/view.listLocations?{{parameters}}";

export function getAllTags(
  fetch: (url: string, init?: RequestInit) => Promise<Response>,
  driveId: string,
  options?: IGetTagsOptions
): Promise<IItemPage<ITagItem>> {
  const { tagType, top = 100 } = options || {};
  const params: Record<string, string> = {
    top: `${top}`
  };

  // listTags API supports getting non-preauth URLs, use it for performance gains
  if (tagType === "things") {
    params["prefer"] = "getStaticThumbnailUrls";
  }

  return graphResponse(fetch(formatURL(tagType === "things" ? allTagsApi : placeTagsApi, { driveId, parameters: new URLSearchParams(params) })));
}

export function getTagsPage(
  fetch: (url: string, init?: RequestInit) => Promise<Response>,
  url: string,
  options?: IGetTagsOptions
): Promise<IItemPage<ITagItem>> {
  const { top = 100 } = options || {};
  const params = {
    top: `${top}`
  };

  return graphResponse(fetch(formatURL(url, { parameters: new URLSearchParams(params) })));
}

export interface IGetPhotoOptions {
  expand?: string[];
  select?: string;
}

export function getOldestPhoto(
  fetch: (url: string, init?: RequestInit) => Promise<Response>,
  driveId: string,
  id?: string
): Promise<IPhoto | undefined> {
  return getPhotos(fetch, driveId, {
    expand: [],
    id,
    orderBy: "asc",
    initialPageDateTaken: new Date("1/2/1970"),
    select: "createdDateTime,fileSystemInfo,lastModifiedDateTime,photo",
    top: 1
  }).then((photosPage) => (photosPage.value.length ? photosPage.value[0] : undefined));
}

export function getPhoto(
  fetch: (url: string, init?: RequestInit) => Promise<Response>,
  driveId: string,
  photoId: string,
  options?: IGetPhotoOptions
): Promise<IPhoto> {
  return getItem(fetch, driveId, photoId, { expand: ["tags"], select: defaultPhotoSelect, ...options }).then((item: IItem) =>
    preparePhoto(item as INetworkPhoto)
  );
}

export interface IGeneralPhotoOptions {
  select?: string;
  top?: number;
}

export interface IGetPhotosOptions extends IGeneralPhotoOptions {
  endDate?: Date;
  expand?: string[];
  id?: string;
  initialPageDateTaken?: Date;
  interval?: string;
  orderBy?: "asc" | "desc";
  personId?: string;
  startDate?: Date;
}

const photosApi = "{{vroomOrigin}}/{{apiVersion2}}/drives/{{driveId}}/items/{{id}}/items?{{parameters}}";

/**
 * Get a set of photos given a range of parameters that are supported by the
 * photos API.
 *
 * @param fetch
 * @param options
 * @returns
 */
export function getPhotos(
  fetch: (url: string, init?: RequestInit) => Promise<Response>,
  driveId: string,
  options?: IGetPhotosOptions
): Promise<IItemPage<IPhoto>> {
  const {
    endDate,
    expand = ["tags"],
    id = "root",
    initialPageDateTaken,
    interval,
    orderBy = "desc",
    select = defaultPhotoSelect,
    startDate = new Date(Date.UTC(1900, 0, 1)),
    top = 100,
    personId
  } = options || {};

  // Build up the filter, we always include "photo ne null".
  // If a person is specified this is the only filter.
  // Without a person the range filter will be included.
  const filter = [
    "photo ne null",
    ...(personId
      ? [`and (detectedEntity/recognizedEntity/id eq '${personId}')`]
      : !initialPageDateTaken
      ? [
          endDate ? `and photo/takenDateTime le ${formatDateAPI(endDate)}` : null,
          startDate ? `and photo/takenDateTime ge ${formatDateAPI(startDate)}` : null
        ]
      : [])
  ];

  const params: { [parameterName: string]: string } = {
    $filter: filter.filter((c) => c).join(" "),
    orderby: `photo/takenDateTime ${orderBy}`,
    select,
    top: `${top}`,
    ump: "1"
  };

  if (personId) {
    expand.push("detectedEntities(expand=recognizedEntity)");
  }

  if (expand.length) {
    params.expand = `${expand.join(",")}`;
  }

  if (interval) {
    params.interval = interval;
  }

  if (initialPageDateTaken) {
    params.initialPageDateTaken = formatDateAPI(initialPageDateTaken);
  }

  // Execute the request and include all the parameters built in the request.
  return graphResponse(fetch(formatURL(photosApi, { driveId, id, parameters: new URLSearchParams(params) }))).then(
    (photoPage: IItemPage<INetworkPhoto>) => {
      return { ...photoPage, value: photoPage.value.map(preparePhoto) };
    }
  );
}

export function getPhotosPage(fetch: (url: string, init?: RequestInit) => Promise<Response>, url: string): Promise<IItemPage<IPhoto>> {
  return graphResponse(fetch(url)).then((photoPage: IItemPage<INetworkPhoto>) => {
    return { ...photoPage, value: photoPage.value.map(preparePhoto) };
  });
}

export interface IRecentPlace {
  countryOrRegion?: string;
  state?: string;
  city?: string;
}

export function getRecentPlaces(
  fetch: (url: string, init?: RequestInit) => Promise<Response>,
  driveId: string,
  location: IRecentPlace,
  options?: IGeneralPhotoOptions
): Promise<IItemPage<IPhoto>> {
  const { top = 100, select = defaultPhotoSelect } = options || {};
  const { countryOrRegion, state, city } = location;

  const filter = `photo ne null${
    countryOrRegion
      ? format(" and location/address/countryOrRegion eq '{{countryOrRegion}}'", { countryOrRegion: escapeODataQuote(countryOrRegion) })
      : ""
  }${state ? format(" and location/address/state eq '{{state}}'", { state: escapeODataQuote(state) }) : ""}${
    city ? format(" and location/address/city eq '{{city}}'", { city: escapeODataQuote(city) }) : ""
  }`;

  const params = {
    filter,
    select,
    groupby: "photo/takenDateTime",
    top: `${top}`,
    expand: "tags",
    orderby: "photo/takenDateTime desc"
  };

  return graphResponse(fetch(formatURL(photosApi, { driveId, id: "root", parameters: new URLSearchParams(params) }))).then(
    (photoPage: IItemPage<INetworkPhoto>) => {
      return { ...photoPage, value: photoPage.value.map(preparePhoto) };
    }
  );
}

export interface IGetSearchResultOptions {
  expandTags?: boolean;
  personIds?: string[];
  startDate?: Date;
  endDate?: Date;
}

// @NOTE: At the moment the ODC VRoom endpoint is still used for converged accounts.
const searchApi = "{{searchOrigin}}/v1.0/drives/{{driveId}}/oneDrive.search(q=@s)?{{parameters}}";

export function getSearchResult(
  fetch: (url: string, init?: RequestInit) => Promise<Response>,
  driveId: string,
  searchText: string,
  options?: IGetSearchResultOptions
): Promise<ISearchPage<IPhoto>> {
  const { expandTags, personIds, startDate, endDate } = options || {};

  // Build up the filter, we always include "image ne null or video ne null".
  // If personIds are specified we include a filter for each person.
  const filter = [
    "( image ne null or video ne null )",
    ...(personIds ? personIds.map((id) => `and (detectedEntity/recognizedEntity/id eq '${id}')`) : []),
    ...(startDate ? [`and photo/takenDateTime ge ${formatDateAPI(startDate)}`] : []),
    ...(endDate ? [`and photo/takenDateTime le ${formatDateAPI(endDate)}`] : [])
  ];

  const params: { [parameterName: string]: string } = {
    "@s": `'${escapeODataQuote(searchText)}'`,
    filter: filter.filter((c) => c).join(" "),
    select: defaultPhotoSelect + ",searchResult",
    top: "70" /* NOTE: We are squeezing as much out of space as we can */
  };

  if (expandTags ?? true) {
    params.expand = "tags";
  }

  return graphResponse(
    fetch(
      formatURL(searchApi, {
        driveId,
        parameters: new URLSearchParams(params)
      })
    )
  ).then((photoPage: ISearchPage<INetworkPhoto>) => {
    return { ...photoPage, value: photoPage.value.map(preparePhoto) };
  });
}

const searchApiV21 = "{{vroomOrigin}}/{{apiVersion2}}/drives/{{driveId}}/root/oneDrive.search?{{parameters}}";

export function getSearchResultV21(
  fetch: (url: string, init?: RequestInit) => Promise<Response>,
  driveId: string,
  searchText: string,
  options?: IGetSearchResultOptions
): Promise<ISearchPage<IPhoto>> {
  const { personIds, startDate, endDate } = options || {};

  // Build up the filter, we always include "photo ne null".
  // If personIds are specified we include a filter for each person.
  const filter = [
    "( photo ne null )",
    ...(personIds ? personIds.map((id) => `and (detectedEntity/recognizedEntity/id eq '${id}')`) : []),
    ...(startDate ? [`and photo/takenDateTime ge '${formatDateAPI(startDate)}'`] : []),
    ...(endDate ? [`and photo/takenDateTime le '${formatDateAPI(endDate)}'`] : [])
  ];

  const params: { [parameterName: string]: string } = {
    q: `'${escapeODataQuote(searchText)}'`,
    filter: filter.filter((c) => c).join(" "),
    top: "70" /* NOTE: We are squeezing as much out of space as we can */,
    prefer: "PhotoSearch"
  };

  return graphResponse(
    fetch(
      formatURL(searchApiV21, {
        driveId,
        parameters: new URLSearchParams(params)
      })
    )
  ).then((photoPage: ISearchPage<INetworkPhoto>) => {
    return { ...photoPage, value: photoPage.value.map(preparePhoto) };
  });
}

export function getTagItems(
  fetch: (url: string, init?: RequestInit) => Promise<Response>,
  driveId: string,
  tagName: string,
  options?: IGeneralPhotoOptions
): Promise<IItemPage<IPhoto>> {
  const { top = 100, select = defaultPhotoSelect } = options || {};
  const filter = `photo ne null and tags/any(tag:tag/name eq '${escapeODataQuote(tagName)}')`;

  const params = {
    filter,
    select,
    groupby: "photo/takenDateTime",
    top: `${top}`,
    expand: "tags",
    orderby: "photo/takenDateTime desc"
  };

  return graphResponse(fetch(formatURL(photosApi, { driveId, id: "root", parameters: new URLSearchParams(params) }))).then(
    (photoPage: IItemPage<INetworkPhoto>) => {
      return { ...photoPage, value: photoPage.value.map(preparePhoto) };
    }
  );
}

const addFavoritesApi = "{{vroomOrigin}}/{{favoritesVersion}}/favorites/driveItems/addFavorite";
export function addFavorite(fetch: (url: string, init?: RequestInit) => Promise<Response>, photoIds: string[]): Promise<void> {
  return graphResponse(
    fetch(addFavoritesApi, {
      body: JSON.stringify({
        value: photoIds.map((id) => ({
          id
        }))
      }),
      headers: { "Content-Type": "application/json" },
      method: "POST"
    })
  );
}

const removeFavoritesApi = "{{vroomOrigin}}/{{favoritesVersion}}/favorites/driveItems/removeFavorite";
export function removeFavorite(fetch: (url: string, init?: RequestInit) => Promise<Response>, photoIds: string[]): Promise<void> {
  return graphResponse(
    fetch(removeFavoritesApi, {
      body: JSON.stringify({
        value: photoIds.map((id) => ({
          id
        }))
      }),
      headers: { "Content-Type": "application/json" },
      method: "POST"
    }),
    graphNoContent
  );
}

const getFavoritesApi = "{{vroomOrigin}}/{{favoritesVersion}}/favorites/driveItems?top=100";
export function getFavorites(fetch: (url: string, init?: RequestInit) => Promise<Response>): Promise<IItemPage<IPhoto>> {
  return graphResponse(fetch(getFavoritesApi)).then((photoPage: IItemPage<INetworkPhoto>) => {
    return {
      ...photoPage,
      value: photoPage.value.map((photo) => ({
        ...preparePhoto(photo),
        viewpoint: {
          isFavorite: true
        }
      }))
    };
  });
}

const getFavoritesApi2 = "{{vroomOrigin}}/{{favoritesVersion}}/favorites/driveItems?top=100&select=id";
export function getFavorites2(fetch: (url: string, init?: RequestInit) => Promise<Response>): Promise<IItemPage<string>> {
  return graphResponse(fetch(getFavoritesApi2)).then((photoPage: IItemPage<INetworkPhoto>) => {
    return {
      ...photoPage,
      value: photoPage.value.map((photo) => photo.id)
    };
  });
}

const updateApi = "{{vroomOrigin}}/{{apiVersion1}}/drives/{{driveId}}/items/{{photoId}}";

export function updatePhoto(
  fetch: (url: string, init?: RequestInit) => Promise<Response>,
  driveId: string,
  photoId: string,
  photo: Partial<Exclude<IPhoto, "id">>
): Promise<IPhoto> {
  return graphResponse<INetworkPhoto>(
    fetch(formatURL(updateApi, { driveId, photoId }), {
      body: JSON.stringify(photo),
      headers: { "Content-Type": "application/json" },
      method: "PATCH"
    }),
    graphJSON
  ).then((photo) => preparePhoto(photo));
}

const uploadApi = "{{vroomOrigin}}/{{apiVersion1}}/drives/{{driveId}}/items/{{parentPath}}/{{filename}}:/content?{{parameters}}";

export function uploadPhoto(
  fetch: (url: string, init?: RequestInit) => Promise<Response>,
  driveId: string,
  file: File,
  parentPath: string
): Promise<IPhoto> {
  const params = {
    select: defaultPhotoSelect
  };

  return graphResponse<IPhoto>(
    fetch(formatURL(uploadApi, { driveId, filename: file.name, parameters: new URLSearchParams(params), parentPath }), {
      method: "PUT",
      body: file,
      headers: {
        "Content-Type": file.type,
        prefer: "synchronousmetadata",
        scenario: "UploadFile",
        scenariotype: "AUO"
      }
    })
  ).then((photo: INetworkPhoto) => preparePhoto(photo));
}
